import PageBookmarkCmp from "components/page-bookmark/PageBookmark";
import HandleClick from "middleware/handle-click";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { ReactElement, RefObject } from "react";
import { useParams } from "react-router-dom";
import { ScreenShotService } from "services/screenshot.service";
import ToastUtils from "utils/toastUtils";

type PageProps = {
    toastRef: RefObject<Toast | null>
}

export default function PageControls({toastRef}: PageProps): ReactElement {
    const { reportId, pageId } = useParams();
    const screenshotService = ScreenShotService.getInstance();
    return <>
        <span
          className="page-controls"
          style={{
            border: "1px solid #dadada",
            borderRadius: "5px",
          }}
        >
          <div
            className="report--controls"
            style={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            {pageId ? <PageBookmarkCmp /> : <></>}
            <Button
              icon="pi pi-undo"
              className="p-button-rounded p-button-text p-button-secondary"
              tooltip="Recarregar"
              tooltipOptions={{ position: "bottom" }}
              aria-label="Submit"
              style={{
                width: "40px",
                height: "40px",
                padding: "0",
                borderRadius: "0",
              }}
              onClick={() =>
                HandleClick.logClickEvents("reports_recarregar", () => {
                  sessionStorage.removeItem("SelectedParamsValue");
                  sessionStorage.removeItem("filterFields");
                  sessionStorage.removeItem("selector-markers");
                  sessionStorage.removeItem("selectedReportId");
                  sessionStorage.removeItem("pageList");
                  window.location.reload()
                })
              }
            />
            <Button
              icon="pi pi-share-alt"
              className="p-button-rounded p-button-text p-button-secondary"
              tooltip="Copiar link"
              tooltipOptions={{ position: "bottom" }}
              style={{
                width: "42px",
                height: "40px",
                padding: "0",
                borderRadius: "0",
                borderRight: "1px solid #dadada",
                borderLeft: "1px solid #dadada",
              }}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toastRef.current?.show({
                  ...ToastUtils.defaultSuccessOptions,
                  ...{ detail: "Link da página copiado" },
                });
              }}
            />
            {
              window.location.href.includes("/admin") ? 
                <></> : 
                <Button
                  icon="pi pi-camera"
                  className="p-button-rounded p-button-text p-button-secondary"
                  tooltip="Foto de tela"
                  tooltipOptions={{ position: "bottom" }}
                  aria-label="Submit"
                  style={{
                    width: "40px",
                    height: "40px",
                    padding: "0",
                    borderRadius: "0",
                  }}
                  onClick={() =>
                    HandleClick.logClickEvents("reports_sandboxShot", () =>
                      screenshotService.openScreenshotArea()
                    )
                  }
                />
            }
            
          </div>
        </span>
    </>
}
import HandleClick from "middleware/handle-click";
import useFiltrosStore from "pages/painel/report/store/FiltrosStore";
import usePageInfoStore from "pages/painel/report/store/PageInfoStore";
import useSelectedParamStore from "pages/painel/report/store/SelectedParamsStore";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import React, { ReactElement, RefObject, useRef, useState } from "react";
import TokenService from "services/token.service";
import UserService from "services/user.service";
import ToastUtils from "utils/toastUtils";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

type PageProps = {
  toastRef: RefObject<Toast | null>
}

export default function DotMenu({toastRef}: PageProps): ReactElement {
  const userInfo = TokenService.getUserInfo();
  const menu = useRef<Menu>(null);
  const resetPageInfo = usePageInfoStore((state: any) => state.reset);
  const resetSelectedParams = useSelectedParamStore(
    (state: any) => state.reset
  );
  const resetAppliedFilters = useFiltrosStore((state: any) => state.reset);
  // react router dom
  const navigate = useNavigate();
  const logout = (): void => {
    resetPageInfo();
    resetSelectedParams();
    resetAppliedFilters();
    Sentry.setUser(null);
    TokenService.removeToken(navigate);
  };
  const logoutItem: MenuItem = {
    label: "Sair",
    icon: "pi pi-fw pi-sign-out",
    command: () => HandleClick.logClickEvents("navbar_logout", logout),
  };
  const getNotificacaoTexto = (): string => {
    return userInfo?.notification
      ? "Desativar notificações"
      : "Ativar notificações";
  };
  const [notificacaoMsg, setNotificacaoMsg] = useState(getNotificacaoTexto());
  const notificacaoItem: MenuItem = {
    label: notificacaoMsg,
    icon: "pi pi-fw pi-envelope",
    command: () =>
      HandleClick.logClickEvents("navbar_atualizar-notificacoes", async () => {
        if (!userInfo) {
          return;
        }
        const response = await UserService.atualizarNotificacoes(
          userInfo.id,
          !userInfo.notification
        );
        if (response.status === 200) {
          userInfo.notification = !userInfo.notification;
          setNotificacaoMsg(getNotificacaoTexto());
          TokenService.setUserInfo(userInfo);
          toastRef.current?.show({
            ...ToastUtils.defaultSuccessOptions,
            ...{ detail: "Preferências de notificação atualizadas!" },
          });
        } else {
          toastRef.current?.show({
            ...ToastUtils.defaultErrorOptions,
            ...{
              detail:
                "Houve um erro ao tentar atualizar suas preferências, tente novamente mais tarde",
            },
          });
        }
      }),
  };
  const menuButton = (
    <>
      <Menu model={[notificacaoItem, logoutItem]} popup ref={menu} />
      <Button
        icon="pi pi-ellipsis-v"
        tooltip="Mais opções"
        tooltipOptions={{ position: 'bottom' }}
        style={{
          color: "#0076b3",
          width: "40px",
          height: "40px",
          padding: "0px",
          border: "none"
        }}
        outlined
        onClick={(event) =>
          HandleClick.logClickEvents("navbar_menu", () =>
            menu.current?.toggle(event)
          )
        }
      />
    </>
  );

  return menuButton;
}
